/* Full viewport height with a sleek gradient background */
.landing-container {
    height: 100vh;
    overflow: hidden; /* Prevents unwanted scrollbars */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(circle, #0f0c29, #302b63, #24243e);
    background-size: 200% 200%; /* Bigger size for smoothness */
    animation: radialAnimation 7s ease-in-out infinite;
    color: white;
    font-family: "Arial", sans-serif;
    position: relative; /* Needed for absolute child elements */
    padding: 20px;
}

/* Radial Gradient Animation */
@keyframes radialAnimation {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0 0;
    }
}


/* Top Navigation Bar */
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.nav-buttons {
    display: flex;
    gap: 10px;
}

.nav-button {
    background: transparent;
    border: 1px solid white;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nav-button:hover {
    background-color: white;
    color: #302b63;
}

.signup {
    background-color: #6c63ff;
    border: none;
    color: white;
}

.signup:hover {
    background-color: #574bce;
}

/* Main Content Section */
.content {
    text-align: center;
    margin-top: 100px;
    max-width: 600px;
}

h1 {
    font-size: 4rem;
    margin-bottom: 10px;
    line-height: 1.2;
}

p {
    font-size: 1.3rem;
    margin-bottom: 30px;
    color: #d1d1d1;
}

/* Email Form */
.email-form {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.email-form input {
    padding: 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
    width: 300px;
}

.email-form button {
    padding: 15px 25px;
    font-size: 1rem;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    background-color: #6c63ff;
    color: white;
    transition: background-color 0.3s ease;
}

.email-form button:hover {
    background-color: #574bce;
}

/* Trial Button */
.trial-button {
    margin-top: 10px;
    padding: 12px 35px;
    font-size: 1rem;
    border: 1px solid #6c63ff;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    transition: all 0.3s ease;
}

.trial-button:hover {
    background-color: #6c63ff;
    color: white;
}
