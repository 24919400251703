.projects-page {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.project-card {
    /*color: #ffffff;*/
    padding: 15px;
    border-radius: 8px;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
}

.project-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.project-status {
    background-color: #ff9800;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 0.6rem;
}

.project-goal,
.project-purpose {
    margin: 8px 0;
}

.project-dates,
.project-meta,
.project-footer {
    font-size: 0.8rem;
    margin-top: 8px;
    color: #b3b3b3;
    display: flex;
    justify-content: space-between;
}

.project-footer {
    margin-top: 12px;
    border-top: 1px solid #333;
    padding-top: 8px;
}
